import styled from 'styled-components'
import { BREAKPOINTS } from '../../constants'

const CIRCLE_DIAMETER_DESKTOP = '80px'
const CIRCLE_DIAMETER_MOBILE = '48px'

export const VideoAdIconInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${CIRCLE_DIAMETER_DESKTOP};
  height: ${CIRCLE_DIAMETER_DESKTOP};
  border-radius: ${CIRCLE_DIAMETER_DESKTOP};
  border: solid 3px #fff;
  font-size: 48px;

  @media (max-width: ${BREAKPOINTS.smMax}px) {
    width: ${CIRCLE_DIAMETER_MOBILE};
    height: ${CIRCLE_DIAMETER_MOBILE};
    border-radius: ${CIRCLE_DIAMETER_MOBILE};
    font-size: 32px;
  }
`
