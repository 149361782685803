import React from 'react'
import Head from 'next/head'
import { Program } from '@4tn/tv-npm-player'
import { isClient } from '../../utils'

const HeadWrapper: React.FC<{ video?: Program; videoIds: string[] }> = ({ video, videoIds }) => {
  if (!video) {
    return (
      <Head>
        <link rel="canonical" href={`https://embed.kijk.nl/video/${videoIds}`} />
      </Head>
    )
  }

  const { title, description, type, guid, image } = video
  return (
    <Head>
      <title>{title}</title>
      <link rel="icon" href="/favicon.ico" />
      <meta name="description" content={description} />
      <meta name="og:title" content={title} />
      <meta name="og:description" content={description} />
      <meta name="og:type" content={type} />
      <meta name="og:image" content={image} />
      {isClient && <meta name="og:url" content={`${location.origin}/video/${guid}`} />}
      <link rel="canonical" href={`https://embed.kijk.nl/video/${videoIds}`} />
    </Head>
  )
}

export default HeadWrapper
