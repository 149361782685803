import styled from 'styled-components'
import { BREAKPOINTS } from '../../constants'

export const SetupErrorContainer = styled.div<{ backgroundImage?: string }>`
  width: 100%;
  height: 100%;
  background: ${(props) => props.backgroundImage} center / cover no-repeat;
`
export const SetupError = styled.div`
  display: grid;
  justify-items: center;
  gap: 40px;
  padding: 0 16px;
  max-width: 848px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  button,
  a {
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    max-width: 320px;
    height: 48px;
    line-height: 48px;
    border-radius: 4px;
    background-color: #33c497;
    color: white;
    border: none;
    cursor: pointer;
    text-decoration: none;
  }

  @media (max-width: ${BREAKPOINTS.smMax}px) {
    gap: 12px;
  }
`

export const SetupErrorMessage = styled.div`
  display: grid;
  justify-items: center;
  gap: 16px;

  img {
    width: 100px;
    height: 100px;
    margin: 0 auto 10px;
  }

  h1 {
    font-size: 32px;
    line-height: 32px;
    font-weight: 700;
    margin-bottom: 0;
  }

  p {
    font-size: 18px;
    line-height: 1.33;
  }

  @media (max-width: ${BREAKPOINTS.smMax}px) {
    gap: 12px;

    h1 {
      font-size: 24px;
    }

    img {
      width: 50px;
      height: 50px;
      margin: 0 auto 10px;
    }

    p {
      font-size: 14px;
    }

    button,
    a {
      font-size: 14px;
      height: 40px;
      line-height: 40px;
    }
  }
`
