import React, { useState, useEffect, Fragment, FunctionComponent } from 'react'
import Player from '@4tn/tv-npm-player'
import LoaderIcon from '../LoaderIcon'
import { TPlayerConfigReact } from '../../../typings/playerConfig'
import { uuid } from '../../utils/uuid'

type TProps = {
  config: TPlayerConfigReact
  initCallback?: (player: Player) => void
}

const PlayerComponent: FunctionComponent<TProps> = (props: TProps): JSX.Element => {
  const { config, initCallback } = props
  const [containerId] = useState(`player-${uuid()}`)

  useEffect(() => {
    new Player(
      {
        ...config,
        containerId,
      },
      initCallback,
    )
  }, [containerId])

  return (
    <Fragment>
      <div className="player" id={containerId}>
        <LoaderIcon />
      </div>
    </Fragment>
  )
}

export default PlayerComponent
