import React from 'react'
import Head from 'next/head'
import { differenceInMinutes, formatDistance, isFuture, isPast } from 'date-fns'
import { nl } from 'date-fns/locale'
import { Program, SETUP_ERRORS } from '@4tn/tv-npm-player'
import { BLUR_PREFIX, reloadCurrentPage, trackClick } from '../../utils'
import { GRADIENT_BACKGROUND, RESTRICTED_VIDEO_ERROR, TRANSLATIONS } from '../../constants'
import * as Styled from './SetupErrorMessage.styled'

function getErrorContext(
  errorGroup: string,
  videoItem?: Program,
): {
  title: string
  message: string
  backgroundImage?: string
  showReloadButton?: boolean
  showIcon?: boolean
  showClickOut?: boolean
} {
  if (errorGroup === SETUP_ERRORS.EMPTY_PLAYLIST && videoItem) {
    const { availableDate } = videoItem

    if (availableDate && isFuture(availableDate)) {
      return {
        title: TRANSLATIONS.NOT_YET_AVAILABLE,
        message: `Kijk deze video ${formatDistance(availableDate, new Date(), {
          addSuffix: true,
          locale: nl,
        })}`,
        showReloadButton: differenceInMinutes(availableDate, new Date()) <= 10,
        backgroundImage: `url(${BLUR_PREFIX}/${videoItem.image})`,
      }
    }

    if (availableDate && isPast(availableDate)) {
      return {
        title: TRANSLATIONS.NOT_AVAILABLE_ANYMORE,
        message: '',
        backgroundImage: `url(${BLUR_PREFIX}/${videoItem.image})`,
      }
    }
  }

  if (errorGroup === SETUP_ERRORS.PLAYLIST_NO_RESULTS || errorGroup === 'Missing video') {
    return {
      title: TRANSLATIONS.NOT_FOUND,
      message: '',
      backgroundImage: `url(${GRADIENT_BACKGROUND})`,
      showIcon: true,
    }
  }

  if (errorGroup === RESTRICTED_VIDEO_ERROR) {
    return {
      title: TRANSLATIONS.DEVICE_NOT_SUPPORTED,
      message: TRANSLATIONS.GO_TO_KIJK,
      backgroundImage: `url(${GRADIENT_BACKGROUND})`,
      showIcon: false,
      showClickOut: true,
    }
  }

  return {
    title: TRANSLATIONS.SOMETHING_WENT_WRONG,
    message: '',
    showReloadButton: true,
    showIcon: true,
    backgroundImage: `url(${GRADIENT_BACKGROUND})`,
  }
}

const SetupErrorMessage: React.FC<{ errorGroup: string; videoItem?: Program }> = ({ errorGroup, videoItem }) => {
  const [context, setContext] = React.useState<{
    title: string
    message: string
    backgroundImage?: string
    showReloadButton?: boolean
    showIcon?: boolean
    showClickOut?: boolean
  }>()
  React.useEffect(() => {
    setContext(getErrorContext(errorGroup, videoItem))
  }, [errorGroup, videoItem])

  function handleClick(): void {
    trackClick(TRANSLATIONS.TRY_AGAIN)
    reloadCurrentPage()
  }

  if (!context) return <div />

  const { title, message, showReloadButton, backgroundImage, showIcon, showClickOut } = context

  return (
    <>
      <Head>
        <title>{context.title}</title>
        <meta property="og:title" content={title} key="title" />
      </Head>
      <Styled.SetupErrorContainer backgroundImage={backgroundImage}>
        <Styled.SetupError>
          <Styled.SetupErrorMessage>
            {showIcon && <img src="https://static.kijk.nl/images/broken-tv.png" alt={title} />}
            <h1>{title}</h1>
            <p>{message}</p>
            {showReloadButton && (
              <button className="btn" onClick={handleClick}>
                {TRANSLATIONS.TRY_AGAIN}
              </button>
            )}
            {showClickOut && videoItem?.kijkLink ? (
              <a className="btn" href={videoItem.kijkLink} rel="noreferrer" target="_blank">
                {TRANSLATIONS.WATCH_VIDEO}
              </a>
            ) : null}
          </Styled.SetupErrorMessage>
        </Styled.SetupError>
      </Styled.SetupErrorContainer>
    </>
  )
}

export default SetupErrorMessage
