import axios from 'axios'

export default async function getClientIP(ipDataURL: string): Promise<string> {
  try {
    const { data } = await axios.get(ipDataURL)
    return data.clientIP || ''
  } catch (error) {
    console.log(error)
    return ''
  }
}
