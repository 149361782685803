import featureTooling, { FEATURES } from './featureTooling'

declare global {
  interface Window {
    webkit?: {
      messageHandlers: {
        observer: {
          postMessage: (msg: string) => any
        }
      }
    }
    androidWebview?: {
      postMessage: (msg: string) => any
    }
  }
}

const postToParent = (payload: any): void => {
  try {
    const msg = {
      __embedPlayer: { ...payload, frameName: window.name },
    }
    if (typeof window.androidWebview !== 'undefined') {
      window.androidWebview.postMessage(JSON.stringify(msg))
      return
    }
    if (typeof window.webkit !== 'undefined' && !!window.webkit.messageHandlers?.observer) {
      window.webkit.messageHandlers.observer.postMessage(JSON.stringify(msg))
      return
    }
    parent.postMessage(JSON.stringify(msg), '*')
  } catch (error) {
    console.error('failed to send post message to parent', error)
  }
}

const postMessageResponse =
  (command: string, callId: string) =>
  (returnValue: any): void => {
    const msg = {
      __embedPlayerReturn: { command, callId, returnValue, frameName: window.name },
    }

    parent.postMessage(JSON.stringify(msg), '*')
  }

export const createProxy = (jwplayer: jwplayer.JWPlayer): void => {
  window.addEventListener(
    'message',
    (event: any) => {
      let json: { __embedPlayerCall?: { command: string; callId: string; position: number } } = {}
      try {
        json = typeof event.data === 'string' ? JSON.parse(event.data) : event.data
      } catch (ignore) {}

      const payload = json?.__embedPlayerCall
      if (!payload) return

      const { command, callId } = payload

      const respond = postMessageResponse(command, callId)

      switch (command) {
        case 'play':
        case 'pause':
          jwplayer[command]()
          respond(jwplayer.getState())
          break
        case 'seek':
          jwplayer.seek(payload.position)
          respond(jwplayer.getState())
          break
        case 'getState':
          respond(jwplayer.getState())
          break
        case 'getPlaylistItem':
          respond(jwplayer.getPlaylistItem())
          break
        case 'timeListener':
          jwplayer.on('time', (jwEvent) => {
            respond(jwEvent)
          })
          break
        case 'playPauseListener':
          ;['adPlay', 'adPause', 'play', 'pause', 'idle', 'stop'].forEach((key) => {
            jwplayer.on(key as any, (jwEvent: any) => {
              respond(jwEvent)
            })
          })
          break
        default:
          console.warn(command ? `command ${command} not supported` : `command missing in payload`)
          break
      }
    },
    false,
  )
  postToParent({ type: 'ready', newstate: jwplayer.getState() })

  if (typeof window.webkit !== 'undefined' || typeof window.androidWebview !== 'undefined') {
    ;['adStarted', 'adComplete', 'adPlay', 'adPause', 'play', 'pause', 'idle', 'stop', 'time'].forEach((key) => {
      jwplayer.on(key as any, (event: any) => {
        postToParent({ type: event.type, event })
      })
    })
  }

  if (featureTooling.isFeatureEnabled(FEATURES.POST_START_EVENTS)) {
    jwplayer.on('firstFrame', () => {
      postToParent({ type: 'player_start', media: jwplayer.getPlaylistItem()?.metadata })
    })
    let ad: { id: string } | null = null
    jwplayer.on('adTime', (event: any) => {
      if (ad === null || ad.id !== event.id) {
        ad = event
        postToParent({
          type: 'player_ad_start',
          media: {
            ad: 1,
            ad_id: event.id,
            ad_position: `${event.adposition}-roll`,
            ad_client: event.client,
            ad_podcount: event.podcount,
            ad_sequence: event.sequence,
            ad_duration: event.duration || 0,
            ...jwplayer.getPlaylistItem()?.metadata,
          },
        })
      }
    })
    jwplayer.on('adComplete', () => {
      ad = null
    })
    jwplayer.on('adError', () => {
      ad = null
    })
  }
}
