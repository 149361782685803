import React from 'react'
import { Program } from '@4tn/tv-npm-player'
import {
  BLUR_PREFIX,
  reloadCurrentPage,
  getLandscapeImage,
  detectAdBlock,
  customInteraction,
  noticeError,
  preloadImage,
} from '../../utils'
import LoaderIcon from '../LoaderIcon'
import * as Styled from '../SetupErrorMessage/SetupErrorMessage.styled'
import * as StyledAd from './VideoAdBlockEnabled.styled'
import { TRANSLATIONS } from '../../constants'

const VideoAdBlockEnabled: React.FC<{
  videoItem?: Program
  videoIds: string[]
  baitUrl: string
  handleAdBlockChange: (detected: boolean) => void
}> = ({ videoItem, videoIds, handleAdBlockChange, baitUrl }) => {
  const [detected, setDetected] = React.useState<boolean>()
  const [backgroundImage, setBackgroundImage] = React.useState<string>(() => {
    if (videoItem) {
      preloadImage(videoItem.image)
      return videoItem.image
    }
    return ''
  })

  React.useEffect(() => {
    if (!backgroundImage) {
      getLandscapeImage(videoIds[0]).then(setBackgroundImage)
    }

    // Validate whether AdBlock detection is working properly
    setTimeout(async () => {
      const detected = await detectAdBlock(baitUrl)
      if (!detected) {
        handleAdBlockChange(false)
        return
      }
      setDetected(true)
    }, 200)
  }, [])

  React.useEffect(() => {
    if (detected) {
      noticeError('Adblock warning shown')

      // Revalidate AdBlock detection after message is shown
      setTimeout(async () => {
        const detected = await detectAdBlock(baitUrl)
        customInteraction(`AdBlock revalidation result: ${detected ? 'detected' : 'not detected'}`)
        if (!detected) {
          handleAdBlockChange(false)
          return
        }
      }, 1000)
    }
  }, [detected])

  if (!detected) {
    return <LoaderIcon />
  }
  return (
    <Styled.SetupErrorContainer
      backgroundImage={backgroundImage ? `url(${BLUR_PREFIX}/${backgroundImage})` : undefined}
    >
      <Styled.SetupError>
        <StyledAd.VideoAdIconInner>!</StyledAd.VideoAdIconInner>
        <Styled.SetupErrorMessage>
          <p>{TRANSLATIONS.ADBLOCK_INFO}</p>
          <button className="btn" onClick={reloadCurrentPage}>
            {TRANSLATIONS.ADBLOCK_OFF}
          </button>
        </Styled.SetupErrorMessage>
      </Styled.SetupError>
    </Styled.SetupErrorContainer>
  )
}

export default VideoAdBlockEnabled
